<template>
  <div class="addAndEditMember page-info-content">
    <!-- 面包屑 -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-position="left"
      label-width="140px"
      class="ruleForm form-item-row"
    >
      <h3 class="page-subtitle-shade">
        基本信息
      </h3>
      <el-form-item label="公司编号" prop="cmpCode">
        <el-input v-model="ruleForm.cmpCode" size="small" disabled placeholder="系统自动生成" />
      </el-form-item>
      <el-form-item label="公司名称" prop="cmpName">
        <el-input v-model="ruleForm.cmpName" size="small" placeholder="请输入公司名称" />
      </el-form-item>
      <el-form-item label="公司简称" prop="cmpShortName">
        <el-input v-model="ruleForm.cmpShortName" size="small" placeholder="请输入公司简称" />
      </el-form-item>
      <el-form-item label="授权系统" prop="cmpRoleList">
        <el-select v-model="ruleForm.cmpRoleList" size="small" multiple placeholder="请选择授权系统">
          <el-option
            v-for="item in $store.getters.getDictionaryItem('SYS_TYPE')"
            :key="item.dictId"
            :disabled="item.dictId === '00'"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="注册资本(万元)" prop="cmpRegFund">
        <el-input-number
          v-model="ruleForm.cmpRegFund"
          :precision="2"
          :controls="false"
          size="small"
          placeholder="请输入注册资本(万元)"
        />
      </el-form-item>
      <el-form-item label="注册地址" prop="cmpAddress">
        <el-input v-model="ruleForm.cmpAddress" size="small" placeholder="请输入注册地址" />
      </el-form-item>
      <h3 class="page-subtitle-shade">
        营业执照信息
      </h3>
      <el-form-item label="统一社会信用代码" prop="cmpUnicode">
        <el-input v-model="ruleForm.cmpUnicode" size="small" placeholder="请输入统一社会信用代码" />
      </el-form-item>
      <el-form-item label="成立日期" prop="cmpDate">
        <el-date-picker
          v-model="ruleForm.cmpDate"
          value-format="yyyy-MM-dd"
          size="small"
          type="date"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item label="营业期限截止日">
        <el-date-picker
          v-model="ruleForm.cmpCloseDate"
          value-format="yyyy-MM-dd"
          size="small"
          type="date"
          placeholder="不选择日期则默认为长期"
        />
      </el-form-item>
      <el-form-item label="登记机关" prop="cmpRegOrg">
        <el-input v-model="ruleForm.cmpRegOrg" size="small" placeholder="请输入登记机关" />
      </el-form-item>
      <el-form-item label="公司类型" prop="cmpType">
        <el-select v-model="ruleForm.cmpType" size="small" placeholder="请选择公司类型">
          <el-option label="国企" value="国企" />
          <el-option label="民营" value="民营" />
          <el-option label="股份制" value="股份制" />
          <el-option label="上市" value="上市" />
        </el-select>
      </el-form-item>
      <el-form-item class="overallWidth" label="营业执照电子版" prop="businessLicense">
        <span class="tip">提示：请上传多证合一营业执照，照片或扫描件；支持JPG、JPEG、PNG格式，大小不超过10M</span>
        <ImageUpload :explain="'上传营业执照'" :can-only-pictures="true" :edit-img-url="ruleForm.businessLicense" @getImgUrl="getImgUrl1" />
      </el-form-item>
      <!-- 法人信息 -->
      <h3 class="page-subtitle-shade">
        法人信息
      </h3>
      <el-form-item label="法人姓名" prop="leadName">
        <el-input v-model="ruleForm.leadName" size="small" placeholder="请输入法人姓名" />
      </el-form-item>
      <el-form-item label="身份证号码" prop="leadPaperid">
        <el-input v-model="ruleForm.leadPaperid" size="small" placeholder="请输入身份证号码" />
      </el-form-item>
      <el-form-item label="身份证开始日期" prop="leadIdcardSdate">
        <el-date-picker
          v-model="ruleForm.leadIdcardSdate"
          value-format="yyyy-MM-dd"
          size="small"
          type="date"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item label="身份证结束日期" prop="leadIdcardEdate">
        <el-date-picker
          v-model="ruleForm.leadIdcardEdate"
          value-format="yyyy-MM-dd"
          size="small"
          type="date"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item class="overallWidth" label="身份证正反面" prop="leadIdcard">
        <span class="tip">提示：请上传身份证照片或扫描件；支持JPG、JPEG、PNG格式，大小不超过10M</span>
        <div class="flexBox">
          <ImageUpload
            :explain="'上传身份证人像面'"
            class="interval"
            :can-only-pictures="true"
            id-card-front="facade"
            :edit-img-url="ruleForm.leadIdcardFront"
            @getImgUrl="getImgUrl2"
          />
          <ImageUpload
            :explain="'上传身份证国徽面'"
            :can-only-pictures="true"
            id-card-front="reverse"
            :edit-img-url="ruleForm.leadIdcardBack"
            @getImgUrl="getImgUrl3"
          />
        </div>
      </el-form-item>
      <h3 class="page-subtitle-shade">
        管理员账号
      </h3>
      <el-form-item label="管理员账户" prop="userCode">
        <el-input
          v-model="ruleForm.userCode"
          size="small"
          :disabled="ruleForm.cmpCode ? true : false"
          placeholder="请输入管理员账户名"
        />
      </el-form-item>
      <el-form-item label="登录初始密码">
        <el-input v-model="ruleForm.password" size="small" :disabled="true" placeholder="" />
      </el-form-item>
      <el-form-item label="管理员姓名" prop="userName">
        <el-input v-model="ruleForm.userName" size="small" placeholder="请输入用户姓名" />
      </el-form-item>
      <el-form-item label="管理员手机号" prop="phoneNo">
        <el-input v-model="ruleForm.phoneNo" size="small" placeholder="请输入管理员手机号" />
      </el-form-item>
      <el-form-item label="管理员身份证" prop="cardNo">
        <el-input v-model="ruleForm.cardNo" size="small" placeholder="请输入管理员身份证号码" />
      </el-form-item>

      <!-- 这些先不要了 -->
      <!-- <el-form-item label="实收资本(万元)" prop="cmpRealFund">
        <el-input-number :precision="2" :controls="false" size="small" placeholder="请输入实收资本(万元)" v-model="ruleForm.cmpRealFund"></el-input-number>
      </el-form-item>
      <el-form-item label="实际控制人" prop="cmpOwner">
        <el-input size="small" placeholder="请输入实际控制人" v-model="ruleForm.cmpOwner"></el-input>
      </el-form-item>
      <el-form-item label="公司状态" prop="businessState">
        <el-input size="small" placeholder="请输入公司状态" v-model="ruleForm.businessState"></el-input>
      </el-form-item>
      <el-form-item label="传真" prop="cmpFax">
        <el-input size="small" type="number" placeholder="请输入传真" v-model="ruleForm.cmpFax"></el-input>
      </el-form-item>
      <el-form-item label="经营范围" prop="cmpBisScope">
        <el-input size="small" type="textarea" maxlength="200" show-word-limit placeholder="请输入经营范围" v-model="ruleForm.cmpBisScope"></el-input>
      </el-form-item>
      <el-form-item label="管理员邮箱">
        <el-input size="small" placeholder="请输入管理员邮箱" v-model="ruleForm.email"></el-input>
      </el-form-item> -->
    </el-form>
    <div class="form-operation">
      <router-link :to="{ path: '/memberManagement/index' }">
        <el-button size="small" plain class="widen-button">
          取消
        </el-button>
      </router-link>
      <el-button size="small" :loading="loading" type="primary" class="widen-button" @click="submitForm('ruleForm')">
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
import ImageUpload from '../../components/ImageUpload'
import { cmpBaseinfoAddAndEdit, cmpBaseinfoDetail } from '../../api/memberManagement'
import { formPattern, rules } from '@/utils/matchFormRegex'
export default {
  components: { ImageUpload },
  data() {
    const validateUserCode = (rule, value, callback) => {
      if (Number(value)) {
        callback(new Error('里面必须含有字母'))
        return
      }
      callback()
    }
    const validateLeadIdcard = (rule, value, callback) => {
      if (!this.ruleForm.leadIdcardFront) {
        callback(new Error('请上传身份证正面'))
        return
      }
      if (!this.ruleForm.leadIdcardBack) {
        callback(new Error('请上传身份证反面'))
        return
      }
      callback()
    }
    return {
      enterpriseRole: this.$store.getters.getDictionaryItem('SYS_TYPE'),
      ruleForm: {
        businessLicense: '',
        leadIdcardFront: '',
        leadIdcardBack: '',
        registerSource: '00',
        password: '123456'
      },
      loading: false,
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/memberManagement/index', name: '会员中心' },
        { path: '', name: '会员信息添加' }],
      rules: {
        leadName: [{ required: true, message: '请输入', trigger: 'blur' }],
        leadIdcardSdate: [{ required: true, message: '请选择', trigger: 'change' }],
        leadIdcardEdate: [{ required: true, message: '请选择', trigger: 'change' }],
        cmpType: [{ required: true, message: '请输入', trigger: 'blur' }],
        cmpRegOrg: [{ required: true, message: '请输入', trigger: 'blur' }],
        cmpCloseDate: [{ required: true, message: '请选择', trigger: 'change' }],
        cmpDate: [{ required: true, message: '请选择', trigger: 'change' }],
        cmpShortName: [{ required: true, message: '请输入', trigger: 'blur' }],
        leadIdcard: [{ required: true, validator: validateLeadIdcard, trigger: ['input', 'blur', 'change'] }],
        cardNo: [{ required: true, ...formPattern.cardVerification }],
        leadPaperid: [{ required: true, ...formPattern.cardVerification }],
        businessLicense: [{ required: true, message: '请上传', trigger: 'change' }],
        cmpRoleList: [{ required: true, message: '请选择', trigger: 'change' }],
        userName: [{ required: true, message: '请输入用户姓名', trigger: 'blur' }],
        cmpName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        cmpUnicode: [...rules.unicode],
        userCode: [{ required: true, message: '请输入登录账号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { validator: validateUserCode, trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }],
        phoneNo: [...rules.phone]
      }
    }
  },
  mounted() {
    if (this.$route.query.cmpCode) {
      cmpBaseinfoDetail(this.$route.query.cmpCode, res => {
        this.ruleForm = { ...res.data, password: '123456' }
        this.ruleForm.registerSource = '00'
      })
      this.breadcrumbItem = [{ path: '/', name: '首页' }, { path: '/memberManagement/index', name: '会员中心' }, { path: '', name: '会员信息编辑' }]
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          cmpBaseinfoAddAndEdit(this.ruleForm, () => {
            this.$message.success('成功！')
            this.$router.push({ path: '/memberManagement/index' })
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 获取图片
    getImgUrl1(value) {
      this.ruleForm.businessLicense = value
      this.validateField('businessLicense')
    },
    getImgUrl2(value) {
      this.ruleForm.leadIdcardFront = value
      this.validateField('leadIdcard')
    },
    getImgUrl3(value) {
      this.ruleForm.leadIdcardBack = value
      this.validateField('leadIdcard')
    },
    // 表单部分字段校验
    validateField(field) {
      this.$refs.ruleForm.validateField(field)
    }
  }
}
</script>

<style lang="scss" scoped>
.addAndEditMember /deep/.overallWidth {
  width: 100% !important;

  .flexBox {
    display: flex;

    .interval {
      margin-right: 32px;
    }
  }

  .tip {
    color: #93959A;
    font-size: 12px;
  }
}
</style>
